import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router'; 
 
Component({
    selector: 'app-sidebarsidebar',
    moduleId : module.id,
    templateUrl: './sidebar.component.html' ,
  })

    export interface RouteInfo {
        path: string;
        title: string;
        icon: string;
        class: string; 
        children?: RouteInfo[];
    } 


export const ROUTES: RouteInfo[] =  [ 
    { path: '/saloondetlst',     title: 'Saloon Detail',         icon:'nc-bank',       class: '' },   
    {path: '/servcmaslist',    title: 'Service',         icon:'nc-bank',    class: '' }, 
    { path: '/emplmaslist',     title: 'Employee',         icon:'nc-bank',       class: '' },
    { path: '/custmastlst',     title: 'Customer',         icon:'nc-bank',       class: '' }, 
    { path: '/servicstartadm',     title: 'Start Service',          icon:'nc-bank',       class: '' },       
    { path: '/servcreportadm',     title: 'Service Report',         icon:'nc-bank',       class: '' },  
     { path: '/emplysrvcrptlstadm',     title: 'Employee Report',         icon:'nc-bank',       class: '' },  
     // { path: '/saloonmaslist',     title: 'Saloon Master',         icon:'nc-bank',       class: '' },
    // { path: '/waitngcustmrslst',     title: 'Waiting Customer',         icon:'nc-bank',       class: '' }, 
]; 

export const SUPERADMIN: RouteInfo[] =  [ 
   { path: '/saloonmaslist',     title: 'Saloon Master',         icon:'nc-bank',       class: '' },     
   { path: '/salesexctlst',     title: 'Sales Excutive',         icon:'nc-bank',       class: '' },  
   { path: '/servcreport',     title: 'Service Report',         icon:'nc-bank',       class: '' },   
   
];

export const EMPLOYEE: RouteInfo[] =  [ 
    { path: '/servicstart',     title: 'Start Service',          icon:'nc-bank',       class: '' }, 
    { path: '/servcreportemp',     title: 'Service Report',         icon:'nc-bank',       class: '' },  
    { path: '/emplysrvcrptlst',     title: 'Employee Report',         icon:'nc-bank',       class: '' },  
 ]; 

  export const SALESEXCT: RouteInfo[] =  [ 
     { path: '/salesaloonmaslst',     title: 'Saloon Master',          icon:'nc-bank',       class: '' },  
  ]; 
@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {  
        if(localStorage.getItem('loggedusertyp') == 'SA')
        {  
            this.menuItems = SUPERADMIN.filter(menuItem => menuItem);
        }
        if(localStorage.getItem('loggedusertyp') == 'E')
        {  
            this.menuItems = EMPLOYEE.filter(menuItem => menuItem);
        }
        if(localStorage.getItem('loggedusertyp') == 'A')
        {  
            this.menuItems = ROUTES.filter(menuItem => menuItem);
        }
        if(localStorage.getItem('loggedusertyp') == 'SE')
        {  
            this.menuItems = SALESEXCT.filter(menuItem => menuItem);
        }
    }
}
