import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http'; 
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown'; 
//import { NgxPaginationModule } from 'ngx-pagination';
// import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent//, 
    //NgxPaginationModule
  ],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA
  // ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBqubqSd1aztUanWAnFhzPndrEbEGx05rg',
    //   libraries: ['places']
    // })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
