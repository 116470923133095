import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { ApiuserService } from '../apiuser.service';
import { api_url } from 'properties'; 

@Component({
  selector: 'app-logins',
  moduleId : module.id,
  templateUrl: './logins.component.html' ,
})
export class loginsComponent implements OnInit {
  // txtusrname: string = '';
  // txtpasswrd: string = '';  
  idnum: number;
  
  constructor(private http: HttpClient,
    private router: Router,
    private userservice: ApiuserService) { }

  ngOnInit(): void {   
     
  }
   
  Login_admin() : void {  

    var getusr = ((document.getElementById("txtuser") as HTMLInputElement).value);
    var getpasswrd = ((document.getElementById("txtpasswrd") as HTMLInputElement).value); 
// alert(getusr);
// alert(getpasswrd);

    if(getusr == "") {
      this.userservice.errormsg('Please enter the User Name');
      return;
    }
    if(getpasswrd == "") {
      this.userservice.errormsg('Please enter the Password');
      return;
    }

    
    var params = {
      "username": getusr,
      "password": getpasswrd,
    }
    var paramsusr = {
      "username": getusr, 
    } 
    
    this.http.post(api_url + '/LoginCredentialGetusr', {
      paramsusr
      }).subscribe((response : any) => {
        console.log(response.status);
      if(response.status == 'true'){
        if(response.Data[0].status == "DEL" || response.Data[0].active == "N") {
          if(response.Data[0].usrgroup == "A") 
            {
              this.userservice.errormsg('Your Saloon login is inactive now. Please contact Super Admin');
            }
            if(response.Data[0].usrgroup == "E") 
              {
                this.userservice.errormsg('Your Saloon login is inactive now. Please contact Saloon Admin');
              }
              if(response.Data[0].usrgroup == "SE") 
              {
                this.userservice.errormsg('Your Saloon login is inactive now. Please contact Saloon Admin');
              }
        } 
        else if(response.Data[0].cnt == "0") {
           
          this.userservice.errormsg('Incorrect Username'); 
        } 
         else {  
          this.http.post(api_url + '/LoginCredentialGet', {
            params
            }).subscribe((response : any) => {
              console.log(response.status);
            if(response.status == 'true'){
              if(response.Data[0].cnt == "0") {
                this.userservice.errormsg('Incorrect Password!!');
              } 
               else { 
                localStorage.setItem('loggeduser', response.Data[0].username);
                localStorage.setItem('usercode', response.Data[0].usercode);
                localStorage.setItem('loggedusertyp', response.Data[0].usrgroup); 
                 if(response.Data[0].usrgroup == 'SA')
              {  
                this.router.navigate(['/saloonmaslist']);
              }
              if(response.Data[0].usrgroup == 'E')
              {  
                this.router.navigate(['/servicstart']);
              }
              if(response.Data[0].usrgroup == 'A')
              {   
                  localStorage.setItem('salooncde', response.Data[0].salooncde); 
                this.router.navigate(['/servicstartadm']);
              }
              if(response.Data[0].usrgroup == 'SE')
              {  
                this.router.navigate(['/salesaloonmaslst']);
              } 
              }
            }
         });
        }
      }
   });

    
     
  }
  } 



