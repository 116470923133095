import { Routes } from '@angular/router'; 
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { loginsComponent } from './pages/logins/logins.component';
import { servcmaslistComponent } from './pages/servcmaslist/servcmaslist.component'; 

export const AppRoutes: Routes = [ 
  {
    path: '', 
    redirectTo: 'servcmaslist',
    pathMatch: 'full',
  },  
  { path: 'logins', component: loginsComponent},
  { path: 'servcmas', redirectTo: 'servcmas' },
  { path: 'servcmaslist', redirectTo: 'servcmaslist' },
  { path: 'custmast', redirectTo: 'custmast' },
  { path: 'custmastlst', redirectTo: 'custmastlst' },
  { path: 'emplmas', redirectTo: 'emplmas' },
  { path: 'emplmaslist', redirectTo: 'emplmaslist' },
  { path: 'saloonmas', redirectTo: 'saloonmas' },
  { path: 'saloonmaslist', redirectTo: 'saloonmaslist' },
  { path: 'saloondet', redirectTo: 'saloondet' },
  { path: 'saloondetlst', redirectTo: 'saloondetlst' },
  { path: 'biologmas', redirectTo: 'biologmas' },
  { path: 'biologlist', redirectTo: 'biologlist' },  
  { path: 'servcstrt', redirectTo: 'servcstrt' },
  { path: 'servcstrtlst', redirectTo: 'servcstrtlst' },
  { path: 'waitngcustmrs', redirectTo: 'waitngcustmrs' },
  { path: 'waitngcustmrslst', redirectTo: 'waitngcustmrslst' },
  { path: 'servicstart', redirectTo: 'servicstart' },
  { path: 'servicstartadm', redirectTo: 'servicstartadm' },
  { path: 'servcreport', redirectTo: 'servcreport' },
  { path: 'servcdetreport', redirectTo: 'servcdetreport' },
  { path: 'servcreportemp', redirectTo: 'servcreportemp' },
  { path: 'servcdetreportemp', redirectTo: 'servcdetreportemp' },
  { path: 'servcreportadm', redirectTo: 'servcreportadm' },
  { path: 'servcdetreportadm', redirectTo: 'servcdetreportadm' },
  { path: 'emplyreport', redirectTo: 'emplyreport' },
  { path: 'emplysrvcrptlst', redirectTo: 'emplysrvcrptlst' },
  { path: 'emplysrvcrpt', redirectTo: 'emplysrvcrpt' },
  { path: 'emplysrvcrptlstadm', redirectTo: 'emplysrvcrptlstadm' },
  { path: 'emplysrvcrptadm', redirectTo: 'emplysrvcrptadm' },
  { path: 'salesexctlst', redirectTo: 'salesexctlst' },
  { path: 'salesexct', redirectTo: 'salesexct' },
  { path: 'salesaloonmas', redirectTo: 'salesaloonmas' },
  { path: 'salesaloonmaslst', redirectTo: 'salesaloonmaslst' },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  },
]},
  {
    path: '**',
    redirectTo: 'servcmaslist'
  }
]
 
